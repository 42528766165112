<template>
  <el-form ref="addAdminForm" :model="adminForm" label-width="120px">
    <el-form-item label="姓名">
      <el-input v-model="adminForm.name"></el-input>
    </el-form-item>
    <el-form-item label="账号">
      <el-input v-model="adminForm.user"></el-input>
    </el-form-item>
    <el-form-item label="密码">
      <el-input type="password" v-model="adminForm.password"></el-input>
    </el-form-item>
    <el-form-item label="内容管理">
      <el-select v-model="adminForm.contentManagement" multiple placeholder="请选择">
        <el-option label="内容管理" value="contentManagement"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="用户管理">
      <el-select v-model="adminForm.userManagement" multiple placeholder="请选择">
        <el-option label="用户管理" value="normalMemberManagement"></el-option>
        <el-option label="新增用户" value="platformMemberManagement"></el-option>
        <el-option label="用户学习详情" value="paymentOrderManagement"></el-option>
        <el-option label="学习数据分析" value="baseMemberFee"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="系统设置">
      <el-select v-model="adminForm.productManagement" multiple placeholder="请选择">
        <el-option label="激活码兑换记录" value="normalProduct"></el-option>
        <el-option label="激活码管理" value="addManagement"></el-option>
        <!-- <el-option label="添加分类" value="addClassification"></el-option> -->
      </el-select>
    </el-form-item>
    <el-form-item label="题库管理">
      <el-select v-model="adminForm.normalMemberOrderManagement" multiple placeholder="请选择">
        <el-option label="新建大类" value="pendingShipmentOrder"></el-option>
        <el-option label="新建科目" value="shippedOrder"></el-option>
        <el-option label="新建大章" value="receivedOrder"></el-option>
        <el-option label="新建小节" value="refundApplicationOrder"></el-option>
        <el-option label="题库查看" value="refundApprovedOrder"></el-option>
        <!-- <el-option label="退款中订单" value="refundingOrder"></el-option>
        <el-option label="退款待处理订单" value="refundPendingOrder"></el-option>
        <el-option label="退款已完成订单" value="refundCompletedOrder"></el-option> -->
      </el-select>
    </el-form-item>
    <el-form-item label="学习文档上传">
      <el-select v-model="adminForm.baseMemberOrderManagement" multiple placeholder="请选择">
        <el-option label="文档上传" value="pendingNewOrder"></el-option>
        <el-option label="文件管理" value="processedOrder"></el-option>
        <el-option label="下载分析" value="refundOrderLogistics"></el-option>
        <!-- <el-option label="待处理退款" value="refundPending"></el-option>
        <el-option label="退款已处理" value="refundProcessed"></el-option> -->
      </el-select>
    </el-form-item>
    <!-- <el-form-item label="优惠券管理">
      <el-select v-model="adminForm.couponManagement" multiple placeholder="请选择">
        <el-option label="上新优惠券" value="newCoupon"></el-option>
      </el-select>
    </el-form-item> -->
    <el-form-item>
      <el-button type="primary" @click="submitForm">创建成员角色</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { ref } from 'vue';
import { ElForm, ElFormItem, ElSelect, ElOption, ElInput, ElButton,ElLoading  } from 'element-plus';
import request from "../utils/request"
export default {
  components: {
    ElForm,
    ElFormItem,
    ElSelect,
    ElOption,
    ElInput,
    ElButton
  },
  setup() {
    const adminForm = ref({
      contentManagement: [],
      userManagement: [],
      productManagement: [],
      normalMemberOrderManagement: [],
      baseMemberOrderManagement: [],
      couponManagement: [],
      name: '',
      user: '',
      password: ''
    });

    const submitForm = () => {
      // 显示加载提示
      const loading = ElLoading.service({
        lock: true,
        text: '正在添加...',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      console.log(adminForm.value);
      request.post('/admin/addmanager', adminForm.value)
      .then(response => {
          console.log(response.data);
          // 关闭加载提示
          loading.close();
          // 处理成功的响应
          if(response.data.success==200){
              alert("添加成功！！！")
          }
          
      })
      .catch(error => {
          console.error(error);
          // 处理错误的响应
           // 关闭加载提示
          loading.close();
          alert("添加失败！！！")
      });
    };

    return {
      adminForm,
      submitForm
    };
  }
};
</script>

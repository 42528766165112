<template>
  <div>
    <el-form :model="chapterForm" @submit.native.prevent="addChapter" class="formStyle">
      <el-form-item label="新建章" style="width: 20%;">
        <el-input v-model="chapterForm.title" placeholder="请输入新建章标题"></el-input>
      </el-form-item>
      <el-form-item label="排序" style="width: 20%;">
        <el-input-number v-model="chapterForm.number" :min="1"></el-input-number>
      </el-form-item>
      <el-form-item label="选择大类" style="width: 20%;">
        <el-select v-model="chapterForm.sid" placeholder="请选择">
          <el-option
            v-for="subject in subjects"
            :key="subject.id"
            :label="subject.title"
            :value="subject.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择课程" style="width: 20%;">
        <el-select v-model="chapterForm.cid" placeholder="请选择">
          <el-option
            v-for="course in courses"
            :key="course.id"
            :label="course.title"
            :value="course.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-button type="primary" @click="addChapter" style="height: 80%;">提交</el-button>
    </el-form>

    <el-table :data="chapters" style="width: 100%">
      <el-table-column prop="title" label="章标题">
        <template #default="{ row }">
          <el-input v-if="row.editing" v-model="row.title" placeholder="Edit chapter title"></el-input>
          <span v-else>{{ row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="number" label="排序">
        <template #default="{ row }">
          <el-input-number v-if="row.editing" v-model="row.number" :min="1"></el-input-number>
          <span v-else>{{ row.number }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="subjectTitle" label="大类">
        <template #default="{ row }">
          <el-select v-if="row.editing" v-model="row.sid" placeholder="Select a subject">
            <el-option
              v-for="subject in subjects"
              :key="subject.id"
              :label="subject.title"
              :value="subject.id">
            </el-option>
          </el-select>
          <span v-else>{{ row.subjectTitle }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="courseTitle" label="课程">
        <template #default="{ row }">
          <el-select v-if="row.editing" v-model="row.cid" placeholder="Select a course">
            <el-option
              v-for="course in courses"
              :key="course.id"
              :label="course.title"
              :value="course.id">
            </el-option>
          </el-select>
          <span v-else>{{ row.courseTitle }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="{ row }">
          <el-button type="text" @click="startEdit(row)">Edit</el-button>
          <el-button type="text" @click="deleteChapter(row.id)">Delete</el-button>
          <el-button v-if="row.editing" type="text" @click="updateChapter(row)">Save</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { ElMessage } from 'element-plus';
import request from '@/utils/request';

export default {
  setup() {
    const chapterForm = ref({ title: '', number: 1, sid: '', cid: '' });
    const chapters = ref([]);
    const subjects = ref([]);
    const courses = ref([]);

    const fetchSubjects = async () => {
      try {
        const response = await request.get('/api/subjects');
        subjects.value = response.data;
      } catch (error) {
        ElMessage.error('Failed to fetch subjects');
      }
    };

    const fetchCourses = async () => {
      try {
        const response = await request.get('/api/courses');
        courses.value = response.data;
        console.log(courses);
      } catch (error) {
        ElMessage.error('Failed to fetch courses');
      }
    };

    const fetchChapters = async () => {
      try {
        const response = await request.get('/api/chapters');
        chapters.value = response.data.map(chapter => ({ ...chapter, editing: false }));
      } catch (error) {
        ElMessage.error('Failed to fetch chapters');
      }
    };

    const addChapter = async () => {
      if (!chapterForm.value.sid || !chapterForm.value.cid) {
        ElMessage.warning('Please select both subject and course');
        return;
      }
      try {
        const response = await request.post('/api/add_chapter', chapterForm.value);
        if (response.status === 200) {
          ElMessage.success('Chapter added successfully');
          fetchChapters();
          chapterForm.value = { title: '', number: 1, sid: '', cid: '' };
        }
      } catch (error) {
        ElMessage.error('Failed to add chapter');
      }
    };

    const deleteChapter = async (id) => {
      try {
        const response = await request.delete(`/api/delete_chapter/${id}`);
        if (response.status === 200) {
          ElMessage.success('Chapter deleted successfully');
          fetchChapters();
        }
      } catch (error) {
        ElMessage.error('Failed to delete chapter');
      }
    };

    const updateChapter = async (chapter) => {
      try {
        const response = await request.patch(`/api/update_chapter/${chapter.id}`, {
          title: chapter.title,
          number: chapter.number,
          sid: chapter.sid,
          cid: chapter.cid
        });
        if (response.status === 200) {
          ElMessage.success('Chapter updated successfully');
          chapter.editing = false;
          fetchChapters();
        }
      } catch (error) {
        ElMessage.error('Failed to update chapter');
      }
    };

    const startEdit = (chapter) => {
      chapter.editing = true;
    };

    fetchSubjects();
    fetchCourses();
    fetchChapters();

    return { chapterForm, chapters, subjects, courses, addChapter, deleteChapter, updateChapter, startEdit };
  },
};
</script>
<style scoped>
  .formStyle{
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>

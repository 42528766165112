<!--
 * @Author: xpaibiancheng 486957763@qq.com
 * @Date: 2024-05-20 08:35:25
 * @LastEditors: xpaibiancheng 486957763@qq.com
 * @LastEditTime: 2024-05-26 23:44:37
 * @FilePath: \sjgj_Vue\src\pages\myUser.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-input v-model="searchText" placeholder="输入账号搜索用户..." @input="filterFiles" style="width: 200px; margin-bottom: 20px;"></el-input>
    <el-upload
      action="https://portmini.ysjyzhang.cn/api/upload/users"
      :on-success="handleUploadSuccess" 
      :on-error="handleUploadError" 
      accept=".xls, .xlsx"
      style="display: inline-block; margin-left: 10px;">
      <el-button size="mini" type="primary">批量导入</el-button>
    </el-upload>
    <el-table :data="filteredFiles" style="width: 100%" stripe>
    </el-table>
    <el-table :data="filteredFiles" style="width: 100%" stripe>
      <el-table-column prop="username" label="用户名"></el-table-column>
      <el-table-column prop="vip" label="会员状态">
        <template #default="{ row }">
          <el-tag :type="row.vip === 1 ? 'danger' : 'success'">
            {{ row.vip === 1 ? '会员' : '非会员' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="注册日期">
        <template #default="{ row }">
          {{ formatDate(row.created_at) }}
        </template> 
      </el-table-column>
      <el-table-column prop="vipstart" label="会员开通时间">
        <template #default="{ row }">
          {{ formatDate(row.vipstart) }}
        </template> 
      </el-table-column>
      <el-table-column prop="vipend" label="会员到期时间">
        <template #default="{ row }">
          {{ formatDate(row.vipend) }}
        </template> 
      </el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column sortable prop="major" label="专业"></el-table-column>
      <el-table-column sortable prop="university" label="大学"></el-table-column>
      <el-table-column sortable prop="grade" label="年级"></el-table-column>
      <el-table-column label="操作" width="200">
        <template #default="{ row }" >
          <el-button size="mini" type="danger" icon="el-icon-delete" @click="deleteUser(row.id)">删除</el-button>
          <el-button size="mini" type="info" icon="el-icon-key" @click="openResetDialog(row)">重置密码</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-model="resetDialogVisible" title="重置密码">
      <el-form  :model="resetForm">
        <el-form-item label="新密码" required>
          <el-input v-model="resetForm.password" type="password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPassword">确认更改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { ref,computed } from 'vue';
import axios from 'axios';
import request from '@/utils/request';

export default {
  setup() {
    const users = ref([]); 

    const resetDialogVisible = ref(false);
    const resetForm = ref({ id: null, password: '' });
    const searchText = ref('');
    const filteredFiles = computed(() => {
      let result = users.value;
      if (searchText.value) {
        result = result.filter(user => user.username.includes(searchText.value));
      }
      return result;
    });
    const fetchUsers = async () => {
      try {
        const response = await request.get('/api/admin/users');
        users.value = response.data;
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    };

    const deleteUser = async (id) => {
      try {
        await request.delete(`/api/admin/users/${id}`);
        fetchUsers(); // Refresh the list after deleting
      } catch (error) {
        console.error('Failed to delete user:', error);
      }
    };
    const filterFiles = () => {
      // 筛选逻辑在 computed property 中已实现
    };
    const formatDate = (dateStr) => {
      console.log("传入的时间",dateStr);
      if(dateStr==null){
        return '暂无信息'
      }
      const date = new Date(dateStr);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    };

    const openResetDialog = (user) => {
      resetForm.value.id = user.id;
      resetDialogVisible.value = true;
    };
    const resetPassword = () => {
      if (!resetForm.value.password) {
        alert('请输入新密码');
        return;
      }
      request.put(`/api/users/reset/${resetForm.value.id}`, { password: resetForm.value.password })
        .then(() => {
          alert('更改成功');
          resetDialogVisible.value = false;
          resetForm.value.password = ''; // 清空密码字段
        })
        .catch(error => {
          console.error('Failed to reset password:', error);
          alert('更改失败');
        });
    };
    const handleUploadSuccess = (response, file, fileList) => {
      console.log("Upload successful:", response);
      alert('文件上传成功');
      fetchUsers(); // 重新加载用户数据
    };

    // 文件上传失败处理函数
    const handleUploadError = (error, file, fileList) => {
      console.error("Upload failed:", error);
      alert('文件上传失败');
    };
    fetchUsers(); // Initialize the user data

    return { 
      users,searchText ,filteredFiles,resetForm,resetDialogVisible,
      filterFiles,deleteUser ,formatDate,openResetDialog,resetPassword,handleUploadSuccess,handleUploadError
    };
  }
};
</script>

<template>
  <div>
    <el-form :model="subjectForm" @submit.native.prevent="addSubject" class="formStyle">
      <el-form-item label="新建大类" style="width: 30%;">
        <el-input v-model="subjectForm.title" placeholder="请输入大类名称"></el-input>
      </el-form-item>
      <el-form-item label="排序位置" style="width: 30%;">
        <el-input-number v-model="subjectForm.number" :min="1"></el-input-number>
      </el-form-item>
      <el-button type="primary" @click="addSubject" style="height: 80%;">提交</el-button>
    </el-form>

    <el-table :data="subjects" style="width: 100%">
      <el-table-column prop="title" label="大类">
        <template #default="{ row }">
          <el-input v-if="row.editing" v-model="row.title" placeholder="请输入大类名称"></el-input>
          <span v-else>{{ row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="number" label="排序">
        <template #default="{ row }">
          <el-input-number v-if="row.editing" v-model="row.number" :min="1"></el-input-number>
          <span v-else>{{ row.number }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="{ row }">
          <el-button type="text" @click="startEdit(row)">点击</el-button>
          <el-button type="text" @click="deleteSubject(row.id)">删除</el-button>
          <el-button v-if="row.editing" type="text" @click="updateSubject(row)">保存</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { ElMessage } from 'element-plus';
import request from '@/utils/request';

export default {
  setup() {
    const subjectForm = ref({ title: '', number: 1 });
    const subjects = ref([]);

    const fetchSubjects = async () => {
      try {
        const response = await request.get('/api/subjects');
        subjects.value = response.data;
      } catch (error) {
        ElMessage.error('Failed to fetch subjects');
      }
    };

    const addSubject = async () => {
      try {
        const response = await request.post('/api/add_subject', subjectForm.value);
        if (response.status === 200) {
          ElMessage.success('Subject added successfully');
          fetchSubjects();
          subjectForm.value = { title: '', number: 1 };
        }
      } catch (error) {
        ElMessage.error('Failed to add subject');
      }
    };

    const deleteSubject = async (id) => {
      try {
        const response = await request.delete(`/api/delete_subject/${id}`);
        if (response.status === 200) {
          ElMessage.success('Subject deleted successfully');
          fetchSubjects();
        }
      } catch (error) {
        ElMessage.error('Failed to delete subject');
      }
    };

    const updateSubject = async (subject) => {
      try {
        const response = await request.patch(`/api/update_subject/${subject.id}`, {
          title: subject.title,
          number: subject.number
        });
        if (response.status === 200) {
          ElMessage.success('Subject updated successfully');
          subject.editing = false;
          fetchSubjects();
        }
      } catch (error) {
        ElMessage.error('Failed to update subject');
      }
    };

    const startEdit = (subject) => {
      subject.editing = true;
    };

    fetchSubjects();

    return { subjectForm, subjects, addSubject, deleteSubject, updateSubject, startEdit };
  },
};
</script>
<style scoped>
  .formStyle{
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>

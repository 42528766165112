<template>
    <div id="user" class="userContain">
      本系统暂不开放~
    </div>
  </template>
  
  <script >
  import { h,} from 'vue'
  import { ElMessageBox } from 'element-plus'
  import axios from  'axios'
  import { ElNotification } from 'element-plus'
  
  import request from "../utils/request"
  export default {
    
    name: 'myUser', 
    data() {
  
  
      return {
        
        user: [],
        drawer: false,
        editingUser: null,
        search:'',
        search2:'',
     
      };
      
    },
    methods: {
  
      openDrawer(row) {
        this.editingUser = row;
        // this.drawer = true;
        console.log(this.drawer);
      },
      blackIt(index, row){
        row.alive = 0
      },
      aliveIt(index, row){
        row.alive = 1
      },
      handleDelete(index, row) {
        this.user = this.user.filter((item) => item.id !== row.id);
      },
      getSvip(){

        request({
          method:"GET",
          url:"/admin/queryprice",
          }).then((res)=>{
            console.log(res);
            if(res.data.status==200){
              var responseData=res.data.msg
              this.user=responseData
            }
        })          
      },
      open(){
        ElMessageBox({
      
      message: h('p', null, [
        h('span', null, '是否确认将注册价格修改为 '),
        h('i', { style: 'color: teal'  }, this.search2),
        
      ]),
      
      }).then((res)=>{
  
      console.log(res);
      // 使用axios实例发送POST请求
      axios.post('https://sjgj.xpwangluo.cn/admin/price', `price=${this.search2}`)
            .then(response => {
             
              console.log(response)
              if(response.data.status=='200'){
                
      
                ElNotification({
                    title: '提示',
                    message: '价格修改成功',
                    type: 'success',
                  })
                      
              }
            })
            .catch(error => {
              // 登录失败，提示错误信息
              console.log(error)
              
            })
    })
  
    }
      
      
      
    },
    mounted:function(){
      
    },
  };
  
  </script>
  
  <style scoped>
    .userContain {
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5); /* 使用半透明白色背景 */
      color: black;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(5px); /* 应用背景模糊效果 */
    }

  </style>
  
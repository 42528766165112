<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>创建新用户</span>
    </div>
    <el-form :model="userForm" label-width="120px">
      <el-form-item label="用户名" required>
        <el-input v-model="userForm.username"></el-input>
      </el-form-item>
      <el-form-item label="密码" required>
        <el-input v-model="userForm.password" type="password"></el-input>
      </el-form-item>
      <el-form-item label="会员状态" required>
        <el-select v-model="userForm.vip" placeholder="请选择" @change="handleVipChange">
          <el-option label="非会员" value="0"></el-option>
          <el-option label="会员" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="会员开始时间" v-if="userForm.vip === '1'">
        <el-date-picker v-model="userForm.vipstart" type="datetime" placeholder="选择日期时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="会员结束时间" v-if="userForm.vip === '1'">
        <el-date-picker v-model="userForm.vipend" type="datetime" placeholder="选择日期时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input v-model="userForm.name"></el-input>
      </el-form-item>
      <!-- 其他字段 -->
      <el-form-item label="专业">
        <el-input v-model="userForm.major"></el-input>
      </el-form-item>
      <el-form-item label="大学">
        <el-input v-model="userForm.university"></el-input>
      </el-form-item>
      <el-form-item label="年级">
        <el-input v-model="userForm.grade"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">提交</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import request from '@/utils/request';
import axios from 'axios';

export default {
  data() {
    return {
      userForm: {
        username: '',
        password: '',
        vip: '',
        vipstart: null,
        vipend: null,
        name: '',
        major: '',
        university: '',
        grade: ''
      }
    };
  },
  methods: {
    submitForm() {
      request.post('/api/admina/addusers', this.userForm)
        .then((response) => {
          console.log("返回信息",response);
          if(response.data.success){
            this.$message({
              type: 'success',
              message: '用户创建成功!'
            });
          }else{
            this.$message({
              type: 'error',
              message: response.data.message
            });
          }
        })
        .catch(error => {
          console.error('创建用户失败:', error);
          this.$message.error('创建用户失败');
        });
    },
    resetForm() {
      this.userForm = {
        username: '',
        password: '',
        vip: '',
        vipstart: null,
        vipend: null,
        name: '',
        major: '',
        university: '',
        grade: ''
      };
    },
    handleVipChange(value) {
      if (value === '0') {
        this.userForm.vipstart = null;
        this.userForm.vipend = null;
      }
    }
  }
};
</script>

/*
 * @Author: xpaibiancheng 486957763@qq.com
 * @Date: 2024-01-31 16:42:56
 * @LastEditors: xpaibiancheng 486957763@qq.com
 * @LastEditTime: 2024-05-26 18:53:55
 * @FilePath: \sjgj_Vue\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import axios from 'axios'

const request =axios.create({
    baseURL:"https://portmini.ysjyzhang.cn",
})
const ImageBaseURL="https://portmini.ysjyzhang.cn/static/"
const ImageBaseURLTwo="https://portmini.ysjyzhang.cn/static"
const FileBaseURL="https://portmini.ysjyzhang.cn"
export default request

export { ImageBaseURL ,FileBaseURL,ImageBaseURLTwo };
<!--
 * @Author: xpaibiancheng 486957763@qq.com
 * @Date: 2024-05-20 08:35:25
 * @LastEditors: xpaibiancheng 486957763@qq.com
 * @LastEditTime: 2024-05-22 00:19:41
 * @FilePath: \sjgj_Vue\src\pages\addShop.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-form @submit.native.prevent="generateCode">
      <el-form-item label="有效天数">
        <el-input v-model.number="form.valid_days"></el-input>
      </el-form-item>
      <el-form-item label="购买价格">
        <el-input v-model.number="form.price"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" native-type="submit">确定</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="codes" style="width: 100%">
      <el-table-column prop="activation_code" label="激活码"></el-table-column>
      <el-table-column prop="valid_days" label="有效天数"></el-table-column>
      <el-table-column prop="price" label="价格"></el-table-column>
      <el-table-column prop="created_at" label="创建时间">
        <template #default="{ row }">
          {{ formatDate(row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template #default="scope">
          <span :style="{ color: scope.row.status === 0 ? 'green' : 'red' }">
            {{ scope.row.status === 0 ? '未使用' : '已使用' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="text" @click="deleteCode(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import request from '@/utils/request';
import { ref } from 'vue';

export default {
  setup() {
    const form = ref({ valid_days: 0, price: 0 });
    const codes = ref([]);

    const generateCode = async () => {
      await request.post('/api/generate_activation_code', form.value);
      fetchCodes();  // Refresh list after adding
    };

    const fetchCodes = async () => {
      const response = await request.get('/api/get_activation_codes');
      codes.value = response.data;
      console.log("当前的状态",response.data);
    };

    const deleteCode = async (id) => {
      await request.post('/api/delete_activation_code', { id });
      fetchCodes();  // Refresh list after delete
    };

    const formatStatus = (row) => {
      return row.status === 0 ? { text: '未使用', type: 'success' } : { text: '已使用', type: 'danger' };
    };
    const formatDate = (dateStr) => {
      console.log("传入的时间",dateStr);
      if(dateStr==null){
        return '暂无信息'
      }
      const date = new Date(dateStr);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    };
    fetchCodes();  // Initial fetch of codes

    return {
      form, codes, generateCode, fetchCodes, deleteCode, formatStatus,formatDate
    };
  }
};
</script>

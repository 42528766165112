<template>
  <div style="width: 100%; margin: auto; padding-top: 20px;">
    <el-input v-model="searchText" placeholder="搜索文本名称..." @input="filterFiles" style="width: 200px; margin-bottom: 20px;"></el-input>
    <el-select v-model="selectedSubject" placeholder="筛选科目" @change="filterFiles" style="margin-left: 20px;">
      <el-option
          v-for="course in courses"
          :key="course.id"
          :label="course.title"
          :value="course.title">
        </el-option>
    </el-select>
    <el-select v-model="sortOrder" placeholder="按上传时间排序" @change="sortFiles" style="margin-left: 20px;">
      <el-option label="升序" value="asc"></el-option>
      <el-option label="降序" value="desc"></el-option>
    </el-select>
    <el-table :data="filteredFiles" style="margin-top: 20px;" stripe>
      <el-table-column prop="description" label="文本名称" width="250"></el-table-column>
      <el-table-column prop="file_path" label="链接地址" width="300">
        <template #default="{ row }">
          <el-link :href="row.file_path" target="_blank">{{ row.file_path }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="上传时间" width="180"></el-table-column>
      <el-table-column prop="subject" label="所属科目" width="100"></el-table-column>
      <el-table-column label="操作" width="500">
        <template #default="{ row }">
          <el-button size="mini" @click="modifyFile(row)">修改文件</el-button>
          <el-button size="mini" @click="modifySubject(row)">修改科目</el-button>
          <el-button size="mini" type="danger" @click="deleteFile(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-model="updateFileFlag" title="更新文件">
      <el-form-item label="文件上传">
        <el-upload
          class="upload-demo"
          drag
          action="https://portmini.ysjyzhang.cn/admin/upload/document"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-upload="beforeUpload"
          :on-success="handleSuccess"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只支持单个文件上传</div>
        </el-upload>
      </el-form-item>
      <span slot="footer" class="dialog-footer">
        <el-button @click="permissionsDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="updatePermissions">保存</el-button>
      </span>
    </el-dialog>
    <el-dialog v-model="updateSubjectFlag" title="修改科目" width="30%">
      <el-select v-model="selectedNewSubject" placeholder="选择科目">
        <el-option
            v-for="course in courses"
            :key="course.id"
            :label="course.title"
            :value="course.title">
          </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateSubjectFlag = false">取消</el-button>
        <el-button type="primary" @click="confirmSubjectChange">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { ref, computed,onMounted } from 'vue'
import { ElInput, ElButton, ElTable, ElTableColumn, ElSelect, ElOption, ElLink, ElMessage } from 'element-plus'
import request from '@/utils/request';
import { FileBaseURL } from '@/utils/request';
import { MessageBox } from '@element-plus/icons-vue';
export default {
  name: 'FileDisplayPage',
  components: {
    ElInput, ElButton, ElTable, ElTableColumn, ElSelect, ElOption, ElLink
  },
  setup() {
    const files = ref([]);

    const searchText = ref('');
    const selectedSubject = ref('');
    const sortOrder = ref('');
    const updateFileFlag= ref(false)
    const updateFileId=ref('')
    const updateFileUrl=ref('')
    const updateSubjectFlag = ref(false);
    const selectedNewSubject = ref('');
    const filteredFiles = computed(() => {
      let result = files.value;
      if (searchText.value) {
        result = result.filter(file => file.description.includes(searchText.value));
      }
      if (selectedSubject.value) {
        result = result.filter(file => file.subject === selectedSubject.value);
      }
      if (sortOrder.value) {
        result.sort((a, b) => {
          const dateA = new Date(a.created_at), dateB = new Date(b.created_at);
          return sortOrder.value === 'asc' ? dateA - dateB : dateB - dateA;
        });
      }
      return result;
    });
    onMounted(async () => {
      get_document_url()
    });
    const get_document_url = async()=>{
      try {
        const response = await request.get('/admin/document/get_documents');
        files.value = response.data.map(intention => ({
            ...intention,
            created_at: formatDateString(intention.created_at)
          }));
      } catch (error) {
        console.error("Failed to fetch files:", error);
      }
    }
    function formatDateString(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0')-8;
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
  
    const confirmSubjectChange = async () => {
      if (updateFileId.value && selectedNewSubject.value) {
        try {
          const response = await request.post('/admin/document/update_subject', {
            id: updateFileId.value,
            newSubject: selectedNewSubject.value
          });
          if (response.data.status === 200) {
            ElMessage({
              type: 'success',
              message: '科目更新成功'
            });
            updateSubjectFlag.value = false;
            get_document_url(); // 刷新文档列表
          } else {
            ElMessage({
              type: 'error',
              message: '科目更新失败'
            });
          }
        } catch (error) {
          console.error('Error updating subject:', error);
          ElMessage({
            type: 'error',
            message: '更新科目失败'
          });
        }
      } else {
        ElMessage({
          type: 'warning',
          message: '请完整填写信息'
        });
      }
    };


    const deleteFile = async (file) => {
      const index = files.value.indexOf(file);
      console.log(index);
      console.log(files.value[index].id);
      const response = await request.post('/admin/del/file_doc', {
            id: files.value[index].id,
      });
      console.log(response);
      if(response.data.success==true){
        if (index > -1) {
          files.value.splice(index, 1);
          ElMessage({
              type: 'success',
              message: '删除成功'
        });
        }
      }else{
        ElMessage({
              type: 'error',
              message: '服务器错误'
        });
      }
      
    };

    const modifyFile = (file) => {
      console.log('Modify file:', file);
      // 实现文件修改逻辑
      updateFileFlag.value = true
      updateFileId.value=file.id
      console.log(updateFileId.value);
    };

    const modifySubject = (file) => {
      console.log('Modify subject for:', file);
      // 实现科目修改逻辑
      updateFileId.value = file.id; // 保存需要修改的文件 ID
      updateSubjectFlag.value = true; // 显示对话框
    };

    const sortFiles = () => {
      // 排序逻辑在 computed property 中已实现
    };

    const filterFiles = () => {
      // 筛选逻辑在 computed property 中已实现
    };

    const handlePreview = file => {
      console.log('Preview file:', file);
    };

    const handleRemove = (file, fileList) => {
      console.log('Remove file:', file);
    };

    const beforeUpload = file => {
      /* const isSingle = form.value.fileList.length < 1;
      if (!isSingle) {
        ElMessage({
          type: 'error',
          message: "只能上传一个文件！"
        });
      }
      return isSingle; */
    };
    const handleSuccess = (response, file, fileList) =>{
      console.log(response);
      updateFileUrl.value=response.file_url
    }
    /* 更新操作 */
    const updatePermissions = async()=>{
      console.log(updateFileId.value,updateFileUrl.value.length);
      if(updateFileUrl.value.length==0){
        ElMessage({
          type: 'error',
          message: "至少上传一个文件"
        });
        return
      }
      try{
        const response = await request.post('/admin/update/document',{id:updateFileId.value,url:FileBaseURL+updateFileUrl.value})
        console.log(response.data.status);
        if(response.data.status==200){
          ElMessage({
            type: 'success',
            message: "更新成功"
          });
          updateFileFlag.value=false
        }else{
          ElMessage({
            type: 'error',
            message: "更新失败"
          });
        }
      }catch(error){
        ElMessage({
          type: 'error',
          message: "更新失败"
        });
      }
    }
    const courses = ref([])
    /* 获取科目 */
    const fetchCourses = async () => {
      try {
        const response = await request.get('/api/courses');
        courses.value = response.data;
        console.log(courses);
      } catch (error) {
        ElMessage.error('Failed to fetch courses');
      }
    };
    fetchCourses()
    return { 
      courses,
      filteredFiles, 
      searchText, 
      selectedSubject, 
      sortOrder, 
      updateFileFlag ,
      updateFileId , 
      updateSubjectFlag,
      selectedNewSubject,
      deleteFile, 
      modifyFile, 
      modifySubject,
      handlePreview, 
      handleRemove,
      beforeUpload,
      handleSuccess,
      updatePermissions,
      confirmSubjectChange,
      fetchCourses
    };
  }
}
</script>

<style scoped>

</style>

<template>
    <!-- <div class="vid-container">
        <div class="container" onclick="onclick">
            <div class="top"></div>
            <div class="bottom"></div>
        </div>
        <div class="center">
            <h2>快学习刷题管理系统</h2>
            <input class="inputbar" v-model="user" style="border-radius: 20px;" type="user" placeholder="账号" />
            <input class="inputbar" v-model="password"  style="border-radius: 20px;" type="password" placeholder="密码" />
            <div class="btn" @click="login">登录</div>
        </div>
    </div> -->
    <div class="conye">
        <div class="center">
            <h2 style="color: white;">快学习刷题管理系统</h2>
            <input class="inputbar" v-model="user" style="border-radius: 20px;" type="user" placeholder="账号" />
            <input class="inputbar" v-model="password"  style="border-radius: 20px;" type="password" placeholder="密码" />
            <div class="btn" @click="login">登录</div>
        </div>
    </div>
  </template>
<script>
import request from "../utils/request"
import {ElLoading  } from 'element-plus';
export default {
    data() {
        return {
            user: '',
            password: '',
        }
    },
    methods: {
        async login() {
            // 显示加载提示
            const loading = ElLoading.service({
                    lock: true,
                    text: '正在添加...',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
            try {
                const response = await request.post('/admin/login', {
                    user: this.user,
                    password: this.password,
                });
                if (response.data.success) {
                    // 关闭加载提示
                    loading.close();
                    console.log(response);
                    // 如果登录成功，保存账号、密码、姓名、身份和权限信息到浏览器本地
                    localStorage.setItem('user', this.user);
                    localStorage.setItem('name', response.data.name);
                    localStorage.setItem('identity', response.data.identity);
                    localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
                    console.log(localStorage.getItem('user'));
                    console.log(localStorage.getItem('name'));
                    console.log(localStorage.getItem('identity'));
                    console.log(localStorage.getItem('permissions'));
                    this.$router.push({ name: 'index' });
                } else {
                    // 关闭加载提示
                    loading.close();
                    console.log(response);
                    alert(response.data.message); // 显示错误消息
                }
            } catch (error) {
                loading.close();
                console.error('登录失败', error);
            }
        }
    }
}


</script>
<style scoped>
/* @import url("https://fonts.googleapis.com/css?family=Raleway:400,700");
*,
*:before,
*:after {
    box-sizing: border-box;
}
body {
    min-height: 100vh;
    font-family: 'Raleway', sans-serif;
    margin: 0;
}
.container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #ddf;
}
.container:hover .top:before,
.container:hover .top:after,
.container:hover .bottom:before,
.container:hover .bottom:after,
.container:active .top:before,
.container:active .top:after,
.container:active .bottom:before,
.container:active .bottom:after {
    margin-left: 300px;
    transform-origin: -300px 50%;
    transition-delay: 0s;
}
.container:hover .center,
.container:active .center {
    opacity: 1;
    transition-delay: 0.2s;
}
.top:before,
.top:after,
.bottom:before,
.bottom:after {
    content: '';
    display: block;
    position: absolute;
    width: 200vmax;
    height: 200vmax;
    top: 50%;
    left: 50%;
    margin-top: -100vmax;
    transform-origin: 0 50%;
    transition: all 0.5s cubic-bezier(0.445, 0.05, 0, 1);
    z-index: 10;
    opacity: 0.65;
    transition-delay: 0.2s;
}
.top:before {
    transform: rotate(45deg);
    background: #fbf308;
}
.top:after {
    transform: rotate(135deg);
    background: #f84949;
}
.bottom:before {
    transform: rotate(-45deg);
    background: #60b8d4;
}
.bottom:after {
    transform: rotate(-135deg);
    background: #3745b5;
}
.center {
    position: absolute;
    width: 400px;
    height: 500px;
    top: 50%;
    left: 50%;
    margin-left: -200px;
    margin-top: -250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.445, 0.05, 0, 1);
    transition-delay: 0s;
    color: #333;
    background-color: rgba(126, 163, 232, 0.138);
    border-radius: 50px;
}
.center input {
    width: 100%;
    padding: 15px;
    margin: 5px;
    border-radius: 1px;
    border: 1px solid #ccc;
    font-family: inherit;
}
.inputbar{
    background-color: #ffffff88;
    box-shadow: 0 2px 5px rgba(161, 161, 161, 0.125);
}
.inputbar:focus {
    outline:none;
    box-shadow: 0 3px 8px rgba(108, 202, 240, 0.676);
}
.btn{
    cursor:pointer;
    background-color: #ebf2fe00;
    padding: 14px;
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 30px;
    box-shadow: 0 2px 5px gray;
    font-size: 25px;
    margin-top: -30px;
}
.btn:hover{
    background-color: #fff;
    box-shadow: 0 3px 8px rgb(15, 240, 180);
    color: rgb(0, 246, 168);
}
.yanzhengma{
    margin-top: -30px;
    width: 300px;
    background-color: #fff;
    height: 80px;
} */
.conye{
    width: 100%;
    height: 100vh;
    background-image: url('../images/logo.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.center {
    width: 400px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    color: #333;
    background-color: rgba(126, 163, 232, 0.138);
    border-radius: 50px;
    
}
.center input {
    width: 100%;
    padding: 15px;
    margin: 5px;
    border-radius: 1px;
    border: 1px solid #ccc;
    font-family: inherit;
}
.inputbar{
    background-color: #ffffff88;
    box-shadow: 0 2px 5px rgba(161, 161, 161, 0.125);
}
.inputbar:focus {
    outline:none;
    box-shadow: 0 3px 8px rgba(108, 202, 240, 0.676);
}
.btn{
    cursor:pointer;
    background-color: #ebf2fe00;
    padding: 14px;
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 30px;
    box-shadow: 0 2px 5px gray;
    font-size: 25px;
    margin-top: -30px;
    color: white;
    font-weight: 500;
}
.btn:hover{
    background-color: #fff;
    box-shadow: 0 3px 8px rgb(15, 240, 180);
    color: rgb(0, 246, 168);
}
</style>
<template>
  <!-- 轮播图语句块 -->
   <div style="width: 95%;margin: auto;">
    <el-carousel interval="5000" height="400px">
      <el-carousel-item v-for="(item, index) in swiperList.url" :key="index">
        <a :href="item.texturl" target="_blank">
          <img :src="item.image" alt="Swiper Image" style="width: 100%; height: 100%;">
        </a>
      </el-carousel-item>
    </el-carousel>
  </div>
  <!-- 轮播图上传 -->
  <div class="banner_swiper">
    <div style="margin-left: 20px;padding-top: 20px;padding-bottom: 10px;"></div>
    <el-form  :model="form" label-width="120px">
      <el-form-item label="轮播图图片">
        <el-upload
          action="https://portmini.ysjyzhang.cn/upload/banner_photos"
          list-type="picture-card"
          :limit="6"
          :on-exceed="handleExceed"
          :before-upload="beforeUpload"
          :on-success="(response) =>handleUploadSuccess(response, 'textbookPhotos')"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <div v-for="(photo, index) in form.textbookPhotos" :key="index" class="photo-entry">
        <el-form-item :label="'文本链接 ' + (index + 1)">
          <el-input v-model="photo.texturl" placeholder="输入链接文本" style="width: 80%;"></el-input>
          <el-button type="danger" icon="el-icon-delete" @click="removePhoto(index)" style="margin-left: 10px;text-align: center;">删除</el-button>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary" style="width: 90%;" @click="submitForm">提交</el-button>
      </el-form-item>
    </el-form>
    <div style="margin-left: 20px;padding-top: 10px;padding-bottom: 10px;"></div>
  </div>
  <!-- 课程展示 -->
  <div class="banner_swiper">
    <div style="margin-left: 20px;padding-top: 20px;padding-bottom: 10px;"></div>
    <el-form  :model="formClass" label-width="120px">
      <el-form-item label="课程安排或公众号">
        <el-upload
          action="https://portmini.ysjyzhang.cn/upload/class_photos"
          list-type="picture-card"
          :limit="1"
          :on-exceed="handleExceed"
          :before-upload="beforeUpload"
          :on-success="(response) =>handleUploadClassSuccess(response)"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" style="width: 90%;" @click="submitFormClass">提交</el-button>
      </el-form-item>
    </el-form>
    <div style="margin-left: 20px;padding-top: 10px;padding-bottom: 10px;"></div>
  </div>
  <!-- 线下导航 -->

</template>

<script>
import { onMounted, ref } from 'vue';
import { ElForm, ElFormItem, ElInput, ElButton, ElUpload, ElMessage } from 'element-plus';
import request from '@/utils/request';
import { ImageBaseURL } from '@/utils/request';
export default {
  name: 'CompanyIntroductionPage',
  components: {
    ElForm, ElFormItem, ElInput, ElButton, ElUpload
  },
  setup() {
    const form = ref({
      textbookPhotos: [],
    });
    const formClass = ref({
      classPhotos:[]
    })
    const swiperList = ref([])

    const handleExceed = (files, fileList) => {
      ElMessage.error('超出图片上传数量限制');
    };
    /* 上传后的回调 */
    const handleUploadSuccess = (response, category) => {
      /* console.log(response);
      console.log("信息",ImageBaseURL); */
      if (response.filepath) {  // 假设后端响应中包含文件路径
          const newPhoto = {
            image: ImageBaseURL + response.filepath,
            texturl: ""  // 初始化为空字符串，等待用户输入
          };
          form.value.textbookPhotos.push(newPhoto);
        }
    };
    /* 课程安排回调 */
    const handleUploadClassSuccess= (response) => {
      if (response.filepath) {  
          formClass.value.classPhotos.push(ImageBaseURL +response.filepath);
        }
    };
    const beforeUpload = (file) => {
      // 可以在此添加文件大小或类型的检查
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      if (!isJPG && !isPNG) {
        ElMessage.error('上传头像图片只能是 JPG 或 PNG 格式!');
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        ElMessage.error('上传图片大小不能超过 2MB!');
        return false;
      }
      return true;
    };

    const submitForm = async() => {
      try {
        const response = await request.post('/subbanner_form', form.value);
        console.log(response.data);
        alert('表单提交成功');
        fetchLastSwiper()
      } catch (error) {
        console.error('Failed to submit form:', error);
        alert('表单提交失败');
      }
    };
    const submitFormClass = async() => {
      try {
        const response = await request.post('/subclass_form', formClass.value);
        console.log(response.data);
        alert('表单提交成功');
      } catch (error) {
        console.error('Failed to submit form:', error);
        alert('表单提交失败');
      }
    };
    const removePhoto = (index) => {
      form.value.textbookPhotos.splice(index, 1);
      ElMessage.success('图片已删除');
    };
    const fetchLastSwiper=()=> {
      request.get('/get_last_swiper')
        .then(response => {
          swiperList.value=response.data
          console.log('Last swiper data:', response.data);
        })
        .catch(error => {
          console.error('Error fetching the last swiper:', error);
      });
    }
    fetchLastSwiper()
    return { form, swiperList,formClass,submitFormClass,handleExceed,beforeUpload, submitForm,handleUploadSuccess,removePhoto,fetchLastSwiper,handleUploadClassSuccess  };
  }
}
</script>

<style scoped>
/* 根据需要添加样式 */
.banner_swiper{
  width: 95%;
  border-radius: 10px;
  background-color: white;
  margin: 20px auto;
}
.photo-entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.photo-entry .el-form-item {
  flex: 1;
}
</style>

<template>
  <div>
    <el-form @submit.prevent="fetchQuestionsBySection">
      <el-form-item label="选择科目">
        <el-select v-model="selectedSubject" placeholder="请选择科目" @change="handleSubjectChange">
          <el-option
            v-for="subject in subjects"
            :key="subject.id"
            :label="subject.title"
            :value="subject.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="选择课程" v-if="courses.length">
        <el-select v-model="selectedCourse" placeholder="请选择课程" @change="handleCourseChange">
          <el-option
            v-for="course in courses"
            :key="course.id"
            :label="course.title"
            :value="course.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="选择章节" v-if="chapters.length">
        <el-select v-model="selectedChapter" placeholder="请选择章节" @change="handleChapterChange">
          <el-option
            v-for="chapter in chapters"
            :key="chapter.id"
            :label="chapter.title"
            :value="chapter.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="选择小节" v-if="sections.length">
        <el-select v-model="selectedSection" placeholder="请选择小节">
          <el-option
            v-for="section in sections"
            :key="section.id"
            :label="section.title"
            :value="section.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="fetchQuestionsBySection">确认</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div>
    <el-button click="toggleOptions">{{ showOptions ? '隐藏选项和填空' : '显示选项和填空' }}</el-button>
    <div style="width: 100%;height: 20px"></div>
    <el-table :data="questions" style="width: 100%">
      <el-table-column prop="question_order" label="题目顺序"></el-table-column>
      <el-table-column prop="major_question_order" label="大题序号"></el-table-column>
      <!-- <el-table-column prop="major_question_requirement" label="大题要求"></el-table-column> -->
      <el-table-column prop="score" label="分值"></el-table-column>
      <el-table-column prop="question_stem" label="题干" width="300">
        <template #default="{ row }">
          <div v-if="isImageUrl(row.question_stem)">
            <img :src="row.question_stem" alt="题干图片" style="height: 100px;">
          </div>
          <div v-else>{{ row.question_stem }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="question_type" label="题型"></el-table-column>
      <el-table-column v-if="showOptions" label="选项A" prop="option_a"></el-table-column>
      <el-table-column v-if="showOptions" label="选项B" prop="option_b"></el-table-column>
      <el-table-column v-if="showOptions" label="选项C" prop="option_c"></el-table-column>
      <el-table-column v-if="showOptions" label="选项D" prop="option_d"></el-table-column>
      <el-table-column v-if="showOptions" label="选项E" prop="option_e"></el-table-column>
      <el-table-column v-if="showOptions" label="选项F" prop="option_f"></el-table-column>
      <el-table-column v-if="showOptions" label="选项G" prop="option_g"></el-table-column>
      <el-table-column v-if="showOptions" prop="fill_blank" label="填空"></el-table-column>
      <el-table-column prop="correct_answer" label="正确答案"></el-table-column>
      <el-table-column prop="explanation" label="解析" width="250">
        <template #default="{ row }">
          <div v-if="isImageUrl(row.explanation)">
            <img :src="row.explanation" alt="解析图片" style="height: 100px;">
          </div>
          <div v-else>{{ row.explanation }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="difficulty" label="难度"></el-table-column>
      <el-table-column label="操作" width="300">
        <template #default="{ row }">
          <el-button size="small" type="danger" @click="deleteQuestion(row.id)" icon="el-icon-delete">删除</el-button>
          <el-button size="small" type="primary" icon="el-icon-edit" @click="startEdit(row)" v-if="canEdit(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-model="editDialogVisible" title="编辑题目" width="80%">
      <el-form :model="editForm" label-width="100px">
        <el-form-item label="题目顺序">
          <el-input-number v-model="editForm.question_order" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="大题序号">
          <el-input-number v-model="editForm.major_question_order" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="大题要求">
          <el-input type="textarea" v-model="editForm.major_question_requirement"></el-input>
        </el-form-item>
        <el-form-item label="分值">
          <el-input-number v-model="editForm.score" :min="0" :step="0.1"></el-input-number>
        </el-form-item>
        <el-form-item label="题干">
          <el-input type="textarea" v-model="editForm.question_stem"></el-input>
        </el-form-item>
        <el-form-item label="题型">
          <el-select v-model="editForm.question_type" placeholder="请选择题型">
            <!-- 题型选项 -->
            <el-option label="单选题" value="单选题"></el-option>
            <el-option label="多选题" value="多选题"></el-option>
            <el-option label="不定项选择题" value="不定项选择题"></el-option>
            <el-option label="判断题" value="判断题"></el-option>
            <el-option label="填空题" value="填空题"></el-option>
            <el-option label="简答题" value="简答题"></el-option>
            <el-option label="阅读题" value="阅读题"></el-option>
            <el-option label="翻译题" value="翻译题"></el-option>
            <el-option label="作文题" value="作文题"></el-option>
            <el-option label="分析题" value="分析题"></el-option>
          </el-select>
        </el-form-item>
        <!-- 添加选项A到G -->
        <el-form-item label="选项A">
          <el-input v-model="editForm.option_a"></el-input>
        </el-form-item>
        <el-form-item label="选项B">
          <el-input v-model="editForm.option_b"></el-input>
        </el-form-item>
        <el-form-item label="选项 C">
          <el-input v-model="editForm.option_c"></el-input>
        </el-form-item>
        <el-form-item label="选项 D">
          <el-input v-model="editForm.option_d"></el-input>
        </el-form-item>
        <el-form-item label="选项 E">
          <el-input v-model="editForm.option_e"></el-input>
        </el-form-item>
        <el-form-item label="选项 F">
          <el-input v-model="editForm.option_f"></el-input>
        </el-form-item>
        <el-form-item label="选项 G">
          <el-input v-model="editForm.option_g"></el-input>
        </el-form-item>
        <!-- 类似地添加选项B到G -->
        <el-form-item label="填空">
          <el-input v-model="editForm.fill_blank"></el-input>
        </el-form-item>
        <el-form-item label="正确答案">
          <el-input v-model="editForm.correct_answer"></el-input>
        </el-form-item>
        <el-form-item label="解析">
          <el-input type="textarea" v-model="editForm.explanation"></el-input>
        </el-form-item>
        <el-form-item label="难度">
          <el-select v-model="editForm.difficulty" placeholder="请选择难度">
            <el-option label="简单" value="简单"></el-option>
            <el-option label="中等" value="中等"></el-option>
            <el-option label="难" value="难"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitEdit">提交修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import request from '@/utils/request';
import { ElMessage } from 'element-plus';

export default {
  setup() {
    const subjects = ref([]);
    const courses = ref([]);
    const chapters = ref([]);
    const sections = ref([]);
    const questions = ref([]);
    const showOptions = ref(true);
    const selectedSubject = ref(null);
    const selectedCourse = ref(null);
    const selectedChapter = ref(null);
    const selectedSection = ref(null);
    

    const editForm = ref({});
    const editDialogVisible= ref(false);

    onMounted(async () => {
      await fetchQuestions();
      fetchSubjects();
    });

    const fetchQuestions = async () => {
      try {
        const response = await request.get('/api/questions');
        questions.value = response.data;
      } catch (error) {
        console.error('Failed to fetch questions:', error);
      }
    };
    const startEdit = (question) => {
      editForm.value = { ...question };
      editDialogVisible.value = true;
    };
    const submitEdit = async () => {
      await request.put(`/api/questions/${editForm.value.id}`, editForm.value);
      editDialogVisible.value = false;
      ElMessage.success('Failed to fetch subjects');
      fetchQuestions();
    };
    const deleteQuestion = async (id) => {
      try {
        await request.delete(`/api/questions/${id}`);
        fetchQuestions(); // Refresh the list
      } catch (error) {
        console.error('Failed to delete question:', error);
      }
    };

    const editQuestion = (question) => {
      console.log('Edit functionality not implemented', question);
    };

    const isImageUrl = (url) => {
      return url && (url.startsWith('http://') || url.startsWith('https://'));
    };

    const canEdit = (row) => {
      return !isImageUrl(row.question_stem) && !isImageUrl(row.explanation);
    };

    const toggleOptions = () => {
      showOptions.value = !showOptions.value;
    };
    const fetchSubjects = async () => {
      const response = await request.get('/section/subjects');
      subjects.value = response.data;
    };

    const handleSubjectChange = async (id) => {
      const response = await request.get(`/section/courses/${id}`);
      courses.value = response.data;
      selectedCourse.value = null;  // Reset
    };

    const handleCourseChange = async (id) => {
      const response = await request.get(`/section/chapters/${id}`);
      chapters.value = response.data;
      selectedChapter.value = null;  // Reset
    };

    const handleChapterChange = async (id) => {
      const response = await request.get(`/section/sections/${id}`);
      sections.value = response.data;
      selectedSection.value = null;  // Reset
    };

    const fetchQuestionsBySection = async () => {
      if (selectedSection.value) {
        const response = await request.get(`/section/questions/${selectedSection.value}`);
        questions.value = response.data;
      }
    };
    return {
        editForm,editDialogVisible,
        subjects, courses, chapters, sections,
        questions, toggleOptions,showOptions,deleteQuestion, 
        editQuestion, isImageUrl, canEdit, fetchQuestions,
        selectedSubject, selectedCourse, selectedChapter, selectedSection,
        handleSubjectChange, handleCourseChange, handleChapterChange, fetchQuestionsBySection,
        startEdit, submitEdit
      };
  }
};
</script>

<template>
  <div>
    <el-form :model="courseForm" @submit.native.prevent="addCourse" class="formStyle">
      <el-form-item label="科目标题">
        <el-input v-model="courseForm.title" placeholder="请输入新建科目"></el-input>
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number v-model="courseForm.number" :min="1"></el-input-number>
      </el-form-item>
      <el-form-item label="请选择大类">
        <el-select v-model="courseForm.sid" placeholder="请选择大类">
          <el-option
            v-for="subject in subjects"
            :key="subject.id"
            :label="subject.title"
            :value="subject.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-button type="primary" @click="addCourse" style="height: 80%;">提交</el-button>
    </el-form>

    <el-table :data="courses" style="width: 100%">
      <el-table-column prop="title" label="科目标题" style="width: 30%;">
        <template #default="{ row }">
          <el-input v-if="row.editing" v-model="row.title" placeholder="Edit course title"></el-input>
          <span v-else>{{ row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="number" label="排序" style="width: 30%;">
        <template #default="{ row }">
          <el-input-number v-if="row.editing" v-model="row.number" :min="1"></el-input-number>
          <span v-else>{{ row.number }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="subjectTitle" label="大类选择">
        <template #default="{ row }">
          <el-select v-if="row.editing" v-model="row.sid" placeholder="请选择大类">
            <el-option
              v-for="subject in subjects"
              :key="subject.id"
              :label="subject.title"
              :value="subject.id">
            </el-option>
          </el-select>
          <span v-else>{{ row.subjectTitle }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="{ row }">
          <el-button type="text" @click="startEdit(row)">编辑</el-button>
          <el-button type="text" @click="deleteCourse(row.id)">删除</el-button>
          <el-button v-if="row.editing" type="text" @click="updateCourse(row)">保存</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { ElMessage } from 'element-plus';
import request from '@/utils/request';

export default {
  setup() {
    const courseForm = ref({ title: '', number: 1, sid: '' });
    const courses = ref([]);
    const subjects = ref([]);

    const fetchSubjects = async () => {
      try {
        const response = await request.get('/api/subjects');
        subjects.value = response.data;
      } catch (error) {
        ElMessage.error('Failed to fetch subjects');
      }
    };

    const fetchCourses = async () => {
      try {
        const response = await request.get('/api/courses');
        courses.value = response.data;
      } catch (error) {
        ElMessage.error('Failed to fetch courses');
      }
    };

    const addCourse = async () => {
      if (!courseForm.value.sid) {
        ElMessage.warning('Please select a subject');
        return;
      }
      try {
        const response = await request.post('/api/add_course', courseForm.value);
        if (response.status === 200) {
          ElMessage.success('Course added successfully');
          fetchCourses();
          courseForm.value = { title: '', number: 1, sid: '' };
        }
      } catch (error) {
        ElMessage.error('Failed to add course');
      }
    };

    const deleteCourse = async (id) => {
      try {
        const response = await request.delete(`/api/delete_course/${id}`);
        if (response.status === 200) {
          ElMessage.success('Course deleted successfully');
          fetchCourses();
        }
      } catch (error) {
        ElMessage.error('Failed to delete course');
      }
    };

    const updateCourse = async (course) => {
      try {
        const response = await request.patch(`/api/update_course/${course.id}`, course);
        if (response.status === 200) {
          ElMessage.success('Course updated successfully');
          course.editing = false;
          fetchCourses();
        }
      } catch (error) {
        ElMessage.error('Failed to update course');
      }
    };

    const startEdit = (course) => {
      course.editing = true;
    };

    fetchSubjects();
    fetchCourses();

    return { courseForm, courses, subjects, addCourse, deleteCourse, updateCourse, startEdit };
  },
};
</script>
<style scoped>
  .formStyle{
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>

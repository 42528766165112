<!--
 * @Author: xpaibiancheng 486957763@qq.com
 * @Date: 2024-04-19 08:49:10
 * @LastEditors: xpaibiancheng 486957763@qq.com
 * @LastEditTime: 2024-05-22 00:54:08
 * @FilePath: \sjgj_Vue\src\pages\addShop.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div style="width: 100%; margin: auto; padding-top: 20px;">
    <el-form  :model="form" label-width="120px">
      <el-form-item label="文件题目/描述">
        <el-input type="textarea" v-model="form.materialDescription" />
      </el-form-item>
      <el-form-item label="文件上传">
        <el-upload
          class="upload-demo"
          drag
          action="https://portmini.ysjyzhang.cn/admin/upload/document"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-upload="beforeUpload"
          :on-success="handleSuccess"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只支持单个文件上传</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="所属学科">
        <el-select v-model="form.subject" placeholder="请选择">
          <el-option
              v-for="course in courses"
              :key="course.id"
              :label="course.title"
              :value="course.title">
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">提交</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
    
  </div>
</template>

<script>
import { ref } from 'vue'
import { ElForm, ElFormItem, ElInput, ElButton, ElUpload, ElSelect, ElOption,ElMessage } from 'element-plus'
import request from '@/utils/request';
import { FileBaseURL } from '@/utils/request';
export default {
  name: 'MaterialDistributionPage',
  components: {
    ElForm, ElFormItem, ElInput, ElButton, ElUpload, ElSelect, ElOption
  },
  setup() {
    const form = ref({
      materialDescription: '',
      subject: '',
      fileList: []
    });
    
    const submitForm = async() => {
      console.log('Submitted form:', form.value);
      console.log('Submitted form:', form.value);
      
      try {
        const response = await request.post('/admin/upload/submit_form', form.value);
        if (response.status === 200) {
          ElMessage.success('上传成功');  // 显示成功消息
        } else {
          ElMessage.error('上传失败: ' + response.data.message);  // 显示错误消息，根据后端实际返回调整
        }
      } catch (error) {
        console.error('Failed to submit form:', error);
        ElMessage.error('提交失败，请检查网络连接或联系管理员');  // 处理请求发送过程中的错误
      }
    };

    const resetForm = () => {
      form.value = {
        materialDescription: '',
        subject: '',
        fileList: []
      };
    };

    const handlePreview = file => {
      console.log('Preview file:', file);
    };

    const handleRemove = (file, fileList) => {
      console.log('Remove file:', file);
    };

    const beforeUpload = file => {
      const isSingle = form.value.fileList.length < 1;
      if (!isSingle) {
        ElMessage({
          type: 'error',
          message: "只能上传一个文件！"
        });
      }
      return isSingle;
    };
    const handleSuccess = (response, file, fileList) =>{
      console.log(response);
      form.value.fileList.push(FileBaseURL+response.file_url)
    }
    const courses = ref([])
    /* 获取科目 */
    const fetchCourses = async () => {
      try {
        const response = await request.get('/api/courses');
        courses.value = response.data;
        console.log(courses);
      } catch (error) {
        ElMessage.error('Failed to fetch courses');
      }
    };
    fetchCourses()
    return { form,courses, submitForm, resetForm, handlePreview, handleRemove, beforeUpload ,handleSuccess,fetchCourses };
  }
}
</script>

<style scoped>
.upload-demo {
  padding: 16px;
  border: 1px dashed #d9d9d9;
  background-color: #fafafa;
  text-align: center;
}
</style>

<template>
  <div>
    <el-table :data="exchangeRecords" style="width: 100%">
      <el-table-column prop="username" label="用户名" width="180"></el-table-column>
      <el-table-column prop="name" label="姓名" width="180"></el-table-column>
      <el-table-column prop="activation_code" label="激活码"></el-table-column>
      <el-table-column prop="created_at" label="用户兑换时间" width="180">
        <template #default="{ row }">
          {{ formatDate(row.created_at) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import request from '@/utils/request';

export default {
  setup() {
    const exchangeRecords = ref([]);

    const fetchExchangeRecords = async () => {
      try {
        const response = await request.get('/admin/exchange_records');
        exchangeRecords.value = response.data;
      } catch (error) {
        console.error("Error fetching exchange records:", error);
      }
    };
    const formatDate = (dateStr) => {
      console.log("传入的时间",dateStr);
      if(dateStr==null){
        return '暂无信息'
      }
      const date = new Date(dateStr);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    };
    onMounted(fetchExchangeRecords);

    return {
      formatDate,
      exchangeRecords
    };
  }
}
</script>

<style>
/* Add custom styles here */
</style>

<template>
  <div>
    <!-- 添加新小节表单 -->
    <el-form :model="sectionForm" @submit.native.prevent="addSection">
      <el-form-item label="选择大类">
        <el-select v-model="sectionForm.sid" placeholder="Select a subject" @change="fetchCourses">
          <el-option
            v-for="subject in subjects"
            :key="subject.id"
            :label="subject.title"
            :value="subject.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择课程" v-if="courses.length > 0">
        <el-select v-model="sectionForm.cid" placeholder="Select a course" @change="fetchChapters">
          <el-option
            v-for="course in courses"
            :key="course.id"
            :label="course.title"
            :value="course.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择大章" v-if="chapters.length > 0">
        <el-select v-model="sectionForm.chid" placeholder="Select a chapter">
          <el-option
            v-for="chapter in chapters"
            :key="chapter.id"
            :label="chapter.title"
            :value="chapter.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="填写小节">
        <el-input v-model="sectionForm.title" placeholder="Enter section title"></el-input>
      </el-form-item>
      <el-form-item label="制定排序">
        <el-input-number v-model="sectionForm.number" :min="1"></el-input-number>
      </el-form-item>
      <el-button type="primary" @click="addSection">提交</el-button>
    </el-form>
    <div style="width: 100%;height: 20px"></div>
    <!-- 展示现有小节信息 -->
    <el-table :data="sections" style="width: 100%" v-if="sections.length > 0">
      <el-table-column width="200" prop="title" label="小节标题">
        <template #default="{ row }">
          <el-input v-if="row.editing" v-model="row.title" placeholder="输入小节标题"></el-input>
          <span v-else>{{ row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column width="150" prop="number" label="小节排序">
        <template #default="{ row }">
          <el-input-number v-if="row.editing" v-model="row.number" :min="1"></el-input-number>
          <span v-else>{{ row.number }}</span>
        </template>
      </el-table-column>
      <el-table-column width="150" prop="subjectTitle" label="所属大类"></el-table-column>
      <el-table-column width="200" prop="courseTitle" label="所属课程"></el-table-column>
      <el-table-column width="200" prop="chapterTitle" label="所属章程"></el-table-column>
      <el-table-column width="300" label="操作">
        <template #default="{ row }">
          <el-button type="text" v-if="!row.editing" @click="editSection(row)">编辑</el-button>
          <el-button type="text" v-if="row.editing" @click="saveSection(row)">保存</el-button>
          <el-button type="text" @click="deleteSection(row.id)">删除</el-button>
          <el-button type="text" @click="uploadTemplate(row.id)">模板上传题库</el-button>
          <el-button type="text" @click="manualInsert(row.id)">手动插入题库</el-button>
        </template>
      </el-table-column>
      
    </el-table>
    <el-dialog v-model="uploadDialogVisible" title="上传题库模板" width="30%">
      <el-upload
        class="upload-demo"
        action="https://portmini.ysjyzhang.cn/api/upload_excel"
        :headers="uploadHeaders"
        :data="{ seid: currentSeid }"
        :on-success="handleUploadSuccess"
        :before-upload="beforeUpload"
        accept=".xlsx, .xls">
        <el-button slot="trigger" type="primary">选择Excel文件</el-button>
        <el-button type="primary" slot="submit" @click="submitUpload">上传到服务器</el-button>
        <div slot="tip" class="el-upload__tip">只支持.xlsx/.xls文件</div>
      </el-upload>
    </el-dialog>
    <el-dialog v-model="manualInsertDialog" title="手动插入题目">
      <el-form :model="manualForm">
        <el-form-item label="题目顺序">
          <el-input-number v-model="manualForm.questionOrder" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="大题序号">
          <el-input-number v-model="manualForm.majorQuestionOrder" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="大题要求">
          <el-input v-model="manualForm.majorQuestionRequirement"></el-input>
        </el-form-item>
        <el-form-item label="分值">
          <el-input-number v-model="manualForm.score" :min="0" :step="0.5"></el-input-number>
        </el-form-item>
        <el-form-item label="题干 (上传图片)">
          <el-upload
            class="upload-demo"
            action="https://portmini.ysjyzhang.cn/api/upload_image"
            :on-success="handleSuccessTiGan"
            accept="image/*">
            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
            <div slot="tip" class="el-upload__tip">文件格式为图片</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="题型">
          <el-select v-model="manualForm.questionType" placeholder="请选择">
            <el-option label="单选题" value="单选题"></el-option>
            <el-option label="多选题" value="多选题"></el-option>
            <el-option label="不定项选择题" value="不定项选择题"></el-option>
            <el-option label="判断题" value="判断题"></el-option>
            <el-option label="填空题" value="填空题"></el-option>
            <el-option label="简答题" value="简答题"></el-option>
            <el-option label="阅读题" value="阅读题"></el-option>
            <el-option label="翻译题" value="翻译题"></el-option>
            <el-option label="作文题" value="作文题"></el-option>
            <el-option label="分析题" value="分析题"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选项 A">
          <el-input v-model="manualForm.optionA"></el-input>
        </el-form-item>
        <!-- 选项 B 到 G 相同格式 -->
        <el-form-item label="选项 B">
          <el-input v-model="manualForm.optionB"></el-input>
        </el-form-item>
        <el-form-item label="选项 C">
          <el-input v-model="manualForm.optionC"></el-input>
        </el-form-item>
        <el-form-item label="选项 D">
          <el-input v-model="manualForm.optionD"></el-input>
        </el-form-item>
        <el-form-item label="选项 E">
          <el-input v-model="manualForm.optionE"></el-input>
        </el-form-item>
        <el-form-item label="选项 F">
          <el-input v-model="manualForm.optionF"></el-input>
        </el-form-item>
        <el-form-item label="选项 G">
          <el-input v-model="manualForm.optionG"></el-input>
        </el-form-item>
        <!-- 省略 C 到 G 为节约篇幅 -->
        <el-form-item label="填空题">
          <el-input v-model="manualForm.fillBlank"></el-input>
        </el-form-item>
        <el-form-item label="正确答案">
          <el-select v-model="manualForm.correctAnswer" multiple placeholder="请选择">
            <el-option label="A" value="A"></el-option>
            <el-option label="B" value="B"></el-option>
            <el-option label="B" value="C"></el-option>
            <el-option label="B" value="D"></el-option>
            <el-option label="B" value="E"></el-option>
            <el-option label="B" value="F"></el-option>
            <!-- 同样的选项直到 G -->
            <el-option label="G" value="G"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="解析 (上传图片)">
          <el-upload
            class="upload-demo"
            action="https://portmini.ysjyzhang.cn/api/upload_image"
            :on-success="handleSuccessExplain"
            accept="image/*">
            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
            <div slot="tip" class="el-upload__tip">文件格式为图片</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="难度">
          <el-select v-model="manualForm.difficulty" placeholder="请选择">
            <el-option label="简单" value="简单"></el-option>
            <el-option label="中等" value="中等"></el-option>
            <el-option label="难" value="难"></el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="submitManualInsert">提交</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { ref ,onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import request from '@/utils/request';
import { ImageBaseURLTwo } from '@/utils/request'
export default {
  setup() {
    const sectionForm = ref({
      sid: '',
      cid: '',
      chid: '',
      title: '',
      number: 1
    });
    const uploadDialogVisible = ref(false);
    const currentSeid = ref(null);
    const uploadHeaders = ref({
      'Authorization': `Bearer ${localStorage.getItem('token')}` // 从localStorage获取token
    });
    const subjects = ref([]);
    const courses = ref([]);
    const chapters = ref([]);
    const sections = ref([]);
    const manualInsertDialog = ref(false);
    const manualForm = ref({
      seid: null,  // 小节ID
      questionOrder: 1,
      majorQuestionOrder: 1,
      majorQuestionRequirement: '',
      score: 0,
      questionStem: '',  // 题干图片路径
      questionType: '',
      optionA: '',
      optionB: '',
      // 初始化选项C到G
      optionC: '',
      optionD: '',
      optionE: '',
      optionF: '',
      optionG: '',
      fillBlank: '',
      correctAnswer: [],
      explanation: '',  // 解析图片路径
      difficulty: ''
    });

    const fetchSubjects = async () => {
      try {
        const response = await request.get('/api/subjects');
        subjects.value = response.data;
      } catch (error) {
        ElMessage.error('Failed to fetch subjects');
      }
    };

    const fetchCourses = async () => {
      if (sectionForm.value.sid) {
        try {
          const response = await request.get(`/api/courses/${sectionForm.value.sid}`);
          courses.value = response.data;
        } catch (error) {
          ElMessage.error('Failed to fetch courses');
        }
      }
    };

    const fetchChapters = async () => {
      if (sectionForm.value.cid) {
        try {
          const response = await request.get(`/api/chapters/${sectionForm.value.cid}`);
          chapters.value = response.data;
        } catch (error) {
          ElMessage.error('Failed to fetch chapters');
        }
      }
    };

    const addSection = async () => {
      if (!sectionForm.value.chid) {
        ElMessage.warning('Please complete the form');
        return;
      }
      try {
        const response = await request.post('/api/add_section', sectionForm.value);
        if (response.status === 200) {
          ElMessage.success('Section added successfully');
          fetchSections(); 
        }
      } catch (error) {
        ElMessage.error('Failed to add section');
      }
    };

    const deleteSection = async (id) => {
      console.log(id);
      try {
        const response = await request.delete(`/api/delete_section/${id}`);
        if (response.status === 200) {
          ElMessage.success('小节删除成功');
          sections.value = sections.value.filter(s => s.id !== id);
        } else {
          throw new Error('删除失败');
        }
      } catch (error) {
        ElMessage.error('删除小节失败: ' + error.message);
      }
    };

    // Placeholder functions for unimplemented features
    const uploadTemplate = (seid) => {
      currentSeid.value = seid;
      uploadDialogVisible.value = true;
    };

    const handleUploadSuccess = (response, file, fileList) => {
      uploadDialogVisible.value = false;
      if (response.error) {
        ElMessage.error(response.error);
      } else {
        ElMessage.success('文件上传成功！');
      }
    };

    const beforeUpload = (file) => {
      const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel';
      if (!isExcel) {
        ElMessage.error('上传文件只能是 Excel 格式!');
        return false;
      }
      return true;
    };

    const submitUpload = () => {
      // 强制触发上传组件的上传行为
      document.querySelector('.el-upload__submit').click();
    };

    const manualInsert = (seid) => {
      currentSeid.value = seid;  // 设置当前小节ID
      manualForm.value.seid = seid;  // 将小节ID设置到表单数据中
      manualInsertDialog.value = true;  // 打开手动插入题目的对话框
    };

    const submitManualInsert = async () => {
      console.log(manualForm.value);
      try {
        const response = await request.post('/api/insert_question', manualForm.value);
        if (response.data.success) {
          ElMessage.success('题目插入成功');
          manualInsertDialog.value = false;
        } else {
          ElMessage.error('插入失败: ' + response.data.message);
        }
      } catch (error) {
        ElMessage.error('插入异常: ' + error.message);
      }
    };
    

    const fetchSections = async () => {
      try {
        const response = await request.get('/api/sections');
        sections.value = response.data.map(section => ({
          ...section,
          editing: false
        }));
      } catch (error) {
        ElMessage.error('Failed to fetch sections');
      }
    };
    const editSection = (section) => {
      section.editing = true; // 启用编辑模式
    };

    const updateSection = async (section) => {
      try {
        const response = await request.patch(`/api/update_section/${section.id}`, {
          title: section.title,
          number: section.number,
          sid: section.sid,
          cid: section.cid,
          chid: section.chid
        });
        if (response.status === 200) {
          ElMessage.success('Section updated successfully');
          section.editing = false; // 关闭编辑模式
          fetchSections(); // 重新加载数据
        }
      } catch (error) {
        ElMessage.error('Failed to update section');
      }
    };
    const saveSection = async (section) => {
      try {
        const response = await request.patch(`/api/update_section/${section.id}`, {
          title: section.title,
          number: section.number
        });
        if (response.status === 200) {
          ElMessage.success('小节信息更新成功');
          section.editing = false; // 关闭编辑模式
        } else {
          throw new Error('更新失败');
        }
      } catch (error) {
        ElMessage.error('更新小节信息失败: ' + error.message);
      }
    };
    const handleSuccessTiGan = (res,file)=>{
      console.log("上传回调的函数",ImageBaseURLTwo+res.path);
      manualForm.value.questionStem= ImageBaseURLTwo+res.path
      console.log(manualForm.value);
    };
    const handleSuccessExplain = (res,file)=>{
      console.log("上传回调的函数",ImageBaseURLTwo+res.path);
      manualForm.value.explanation= ImageBaseURLTwo+res.path
      console.log(manualForm.value);
    };
    onMounted(() => {
      fetchSections(); // 页面加载完成后获取小节信息
    });
    fetchSubjects();

    return { 
      sectionForm, 
      subjects, 
      courses, 
      chapters, 
      sections,
      saveSection,
      editSection, 
      updateSection, 
      fetchSections,
      fetchCourses, 
      fetchChapters, 
      addSection, 
      deleteSection, 
      uploadTemplate, 
      manualInsert,
      uploadDialogVisible,
      currentSeid,
      uploadHeaders,
      handleUploadSuccess,
      beforeUpload,
      submitUpload,
      manualInsertDialog,
      manualForm,
      submitManualInsert,
      handleSuccessTiGan,
      handleSuccessExplain
    };
  },
};
</script>

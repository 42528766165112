<!--
 * @Author: xpaibiancheng 486957763@qq.com
 * @Date: 2024-05-20 08:35:25
 * @LastEditors: xpaibiancheng 486957763@qq.com
 * @LastEditTime: 2024-05-26 01:59:14
 * @FilePath: \sjgj_Vue\src\pages\myPayment.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-table
      :data="examInfo"
      style="width: 100%"
      :default-sort="{prop: 'accuracy', order: 'descending'}"
      :row-class-name="rowClassName"
    >
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="cotitle" label="科目" ></el-table-column>
      <el-table-column prop="chtitle" label="大章" ></el-table-column>
      <el-table-column prop="setitle" label="小节" ></el-table-column>
      <el-table-column prop="effective_answer_part" label="有效题数" ></el-table-column>
      <el-table-column prop="actual_answer_part" label="有效作答题数" ></el-table-column>
      <el-table-column prop="correct_num" label="正确数量" ></el-table-column>
      <el-table-column prop="error_num" label="错误数量" ></el-table-column>
      <el-table-column prop="subTime" label="提交时间">
        <template #default="{ row }">
          {{ formatDate(row.subTime) }}
        </template> 
      </el-table-column>
      <el-table-column prop="username" label="用户名" width="180"></el-table-column>
      <el-table-column prop="university" label="大学"  sortable></el-table-column>
      <el-table-column prop="major" label="专业"sortable></el-table-column>
      <el-table-column prop="accuracy" label="正确率" sortable></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

import request from '@/utils/request';

export default {
  setup() {
    const examInfo = ref([]);

    const fetchExamInfo = async () => {
      try {
        const response = await request.get('/api/exam_info');
        examInfo.value = response.data.map(info => {
          const accuracy = (info.correct_num / info.actual_answer_part * 100).toFixed(2);
          info.accuracy = accuracy + '%';
          return info;
        });
      } catch (error) {
        console.error("Error fetching exam info:", error);
      }
    };
    const formatDate = (dateStr) => {
      /* console.log("传入的时间",dateStr); */
      if(dateStr==null){
        return '暂无信息'
      }
      const date = new Date(dateStr);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    };
    const rowClassName = ({row}) => {
      console.log(parseFloat(row.accuracy));
      // 解析去掉'%'字符后的数值
      const accuracyPercentage = parseFloat(row.accuracy.replace('%', ''));
      // 只有当正确率小于60%时才应用警告样式
      return accuracyPercentage < 60 ? 'warning-row' : '';
    };
    onMounted(fetchExamInfo);

    return {
      formatDate,
      rowClassName,
      examInfo
    };
  }
}
</script>

<style>
/* Add custom styles here */
.warning-row {
  background-color: #ed4343;
}
</style>
